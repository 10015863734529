import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import '../styles/DarkMode.css'; // Assuming DarkMode.css handles the theme classes

const useDarkMode = (): ['light' | 'dark', () => void] => {
  const getInitialTheme = (): 'light' | 'dark' => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    return savedTheme || 'light';  // Default to 'light'
  };

  const [theme, setTheme] = useState<'light' | 'dark'>(getInitialTheme);

  useEffect(() => {
    document.documentElement.className = theme;
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return [theme, toggleTheme];
};

const DarkMode: React.FC = () => {
  const [theme, toggleTheme] = useDarkMode();

  return (
    <div className="dark-mode-toggle">
      <button onClick={toggleTheme}>
        {theme === 'light' ? (
          <FontAwesomeIcon icon={faMoon} size="2x" /> // Dark mode icon (fa-moon)
        ) : (
          <FontAwesomeIcon icon={faSun} size="2x" />  // Light mode icon (fa-sun)
        )}
      </button>
    </div>
  );
};

export default DarkMode;
