import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons'
const Home: React.FC = () => {
  return (
    <main>
      <div>
        <h1>Welcome</h1>
        <section id="homepage">
          <h3>We specialize in web design, development, and beyond. Explore our work and reach out if you need a hand!</h3>
          <FontAwesomeIcon icon={faCircleNodes} size="3x"  />
        </section>
      </div>
    </main>
  );
};

export default Home;
