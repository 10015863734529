import React from 'react';
import { motion } from 'framer-motion';
import '../styles/About.css';

const About: React.FC = () => {
  return (
    <main>
      <div>
        <h1>About</h1>
        <motion.section className="aboutSection"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div><h3>We solve problems efficiently, blending thoughtful design with structured development.</h3> </div>
          <ul className="aboutList">
            <li>Design and Strategies</li>
            <li>Web Development</li>
            <li>Shopify and Ecommerce Systems</li>
            <li>Content Management Systems</li>
            <li>DNS, Hosting, Performance Optimization</li>
            <li>SEO, Analytics, Data</li>
          </ul>
        </motion.section>
      </div>
    </main>
  );
};

export default About;
