import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';


const Header: React.FC = () => {
  return (
    <header>
      <Link to="/"><h2>Hico</h2></Link>
      <HamburgerMenu />
    </header>
  );
};

export default Header;
