import React from 'react';
import PortfolioItem from './PortfolioItem';
import portfolioData from '../data/portfolioItems.json';


const Work: React.FC = () => {
  return (
    <main>
      <div>
        <h1>Work</h1>
        <div><h3>Explore samples of our work.</h3> </div>
        <section id="portfolio"
        >
          {portfolioData.map((project) => (
            <PortfolioItem
              key={project.id}
              project={project}
            />
          ))}

        </section>

      </div>
    </main>
  );
};

export default Work;