import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Hamburger.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const HamburgerMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (

        <div className="hamburger-menu">
            <button className="hamburger-button" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} size="2x" />
            </button>
            <nav className={`menu ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li className="logo">
                        <NavLink
                            to="/"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            <h2>Hico</h2>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to="/about"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            About
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/work"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            Work
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/contact"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default HamburgerMenu;
