import React from 'react';
import DarkMode from './DarkMode'; // Assuming DarkMode.tsx is in the same directory

const Footer: React.FC = () => {
  return (
    <footer>
      <p>© HICO</p>
      <DarkMode />
    </footer>
  );
};

export default Footer;
