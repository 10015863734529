import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion';
import '../styles/Contact.css';


const Contact: React.FC = () => {

  return (
    <main>
      <motion.div className="contactWrap"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Contact</h1>
        <div><h3>If you need help, please email us!</h3> </div>
        <a href="mailto:info@hico.dev"> <FontAwesomeIcon icon={faEnvelope} size="2x" /> info@hico.dev</a>
      </motion.div>
    </main>
  );
};

export default Contact;